import React from 'react'
import { useContext } from '~/hooks'
import { PageProps } from 'gatsby'
import { Head, Link } from '~/components'
import * as st from '~/assets/styl/Login.module.styl'
import logo from '~/assets/svg/logo.svg'

const PasswordSent = ({ location }: PageProps) => {
  // Teste do componente de loading
  // const { setLoading } = useContext()
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => setLoading(false), 2000)
  // }, [])
  const { profile } = useContext()

  return (
    <>
      <Head location={location} />
      <main className="bkg-main">
        <div className={st.core}>
          <img src={logo} alt="Logo da Seventh" />
          <section>
            <h1>Olá, {profile?.name}</h1>
            <p>Enviamos um e-mail com instruções para:</p>
            <p className={st.email}>
              <strong>{profile?.emails}</strong>
            </p>
            <p>Verifique sua caixa de entrada e também sua caixa de SPAM.</p>
            <Link className="btn blue mt-10 mb-10" href="/solicitar-senha">
              Solicitar novamente
            </Link>
            <Link href="/" className="gray-underline">
              Voltar
            </Link>
          </section>
          <small>
            <a href="https://www.tiki.com.br/">Powered by tiki</a>
          </small>
        </div>
      </main>
    </>
  )
}

export default PasswordSent
